<template>
  <div class="statistique">
    <div class="containerPage">
      <article>
        <div class="left">
          <h1>{{ $t("STATISTICS") }}</h1>
          <div class="top" v-if="getstatistique">
            <div class="block">
              <div class="total">
                <div class="amount">
                  <h3>{{ $t("TOTAL") }} {{ $t("CUSTOMER") }}</h3>
                  <h1>{{ getstatistique.Total_clients }}</h1>
                </div>
                <div class="profil">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25.865"
                      height="21.454"
                      viewBox="0 0 25.865 21.454"
                    >
                      <g
                        id="Icon_feather-users"
                        data-name="Icon feather-users"
                        transform="translate(0.75 0.769)"
                      >
                        <path
                          id="Tracé_2297"
                          data-name="Tracé 2297"
                          d="M1.5,29.145V26.93A4.43,4.43,0,0,1,5.93,22.5h8.86a4.43,4.43,0,0,1,4.43,4.43v2.215"
                          transform="translate(5.145 -9.21)"
                          fill="none"
                          stroke="#28367a"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1.5"
                        />
                        <path
                          id="Tracé_2298"
                          data-name="Tracé 2298"
                          d="M7.5,8.93A4.43,4.43,0,1,0,11.93,4.5,4.43,4.43,0,0,0,7.5,8.93Z"
                          transform="translate(3.575 -4.5)"
                          fill="none"
                          stroke="#28367a"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1.5"
                        />
                        <path
                          id="Tracé_2299"
                          data-name="Tracé 2299"
                          d="M30,29.2V26.981A4.43,4.43,0,0,1,33.323,22.7"
                          transform="translate(-30 -9.261)"
                          fill="none"
                          stroke="#28367a"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1.5"
                        />
                        <path
                          id="Tracé_2300"
                          data-name="Tracé 2300"
                          d="M27.331,4.7a4.43,4.43,0,0,0,0,8.583"
                          transform="translate(-19.579 -4.551)"
                          fill="none"
                          stroke="#28367a"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1.5"
                        />
                      </g>
                    </svg>
                  </span>
                </div>
              </div>
              <!-- <p class="stonk">
                <span>
                  2.3%
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="16"
                    height="16"
                  >
                    <path
                      d="M13 12V20H11V12H4L12 4L20 12H13Z"
                      fill="rgba(61,213,152,1)"
                    ></path>
                  </svg>
                </span>
                Plus que le mois dernier
              </p> -->
            </div>

            <div class="block">
              <div class="total">
                <div class="amount">
                  <h3>{{ $t("TOTAL") }} {{ $t("ESTIMATE") }}</h3>
                  <h1>{{ getstatistique.Total_devis }}</h1>
                </div>
                <div class="profil">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24.2"
                      height="28.2"
                      viewBox="0 0 24.2 32.2"
                    >
                      <g id="edition" transform="translate(-63.9 0.1)">
                        <path
                          id="Tracé_2209"
                          data-name="Tracé 2209"
                          d="M88,0H67.25V3.25H64V32H84.75V28.75H88ZM64.75,31.25V4H79V9h5V31.25Zm15-26.72,3.72,3.72H79.75ZM87.25,28h-2.5V8.47L79.53,3.25H68V.75H87.25Z"
                          fill="#28367a"
                          stroke="#28367a"
                          stroke-width="0.2"
                        />
                        <path
                          id="Tracé_2210"
                          data-name="Tracé 2210"
                          d="M118,116h10v.75H118Z"
                          transform="translate(-50.625 -108.75)"
                          fill="#28367a"
                          stroke="#28367a"
                          stroke-width="0.2"
                        />
                        <path
                          id="Tracé_2211"
                          data-name="Tracé 2211"
                          d="M118,148h8v.75h-8Z"
                          transform="translate(-50.625 -138.75)"
                          fill="#28367a"
                          stroke="#28367a"
                          stroke-width="0.2"
                        />
                        <path
                          id="Tracé_2212"
                          data-name="Tracé 2212"
                          d="M206,196h3.25v.75H206Z"
                          transform="translate(-133.125 -183.75)"
                          fill="#28367a"
                          stroke="#28367a"
                          stroke-width="0.2"
                        />
                        <path
                          id="Tracé_2213"
                          data-name="Tracé 2213"
                          d="M206,228h3.25v.75H206Z"
                          transform="translate(-133.125 -213.75)"
                          fill="#28367a"
                          stroke="#28367a"
                          stroke-width="0.2"
                        />
                        <path
                          id="Tracé_2214"
                          data-name="Tracé 2214"
                          d="M118,292h8.75v.75H118Z"
                          transform="translate(-50.625 -273.75)"
                          fill="#28367a"
                          stroke="#28367a"
                          stroke-width="0.2"
                        />
                        <path
                          id="Tracé_2215"
                          data-name="Tracé 2215"
                          d="M118,356h8.75v.75H118Z"
                          transform="translate(-50.625 -333.75)"
                          fill="#28367a"
                          stroke="#28367a"
                          stroke-width="0.2"
                        />
                        <path
                          id="Tracé_2216"
                          data-name="Tracé 2216"
                          d="M206,260h3.25v.75H206Z"
                          transform="translate(-133.125 -243.75)"
                          fill="#28367a"
                          stroke="#28367a"
                          stroke-width="0.2"
                        />
                        <path
                          id="Tracé_2217"
                          data-name="Tracé 2217"
                          d="M118,388h8.75v.75H118Z"
                          transform="translate(-50.625 -363.75)"
                          fill="#28367a"
                          stroke="#28367a"
                          stroke-width="0.2"
                        />
                        <path
                          id="Tracé_2218"
                          data-name="Tracé 2218"
                          d="M118,420h7v.75h-7Z"
                          transform="translate(-50.625 -393.75)"
                          fill="#28367a"
                          stroke="#28367a"
                          stroke-width="0.2"
                        />
                        <path
                          id="Tracé_2219"
                          data-name="Tracé 2219"
                          d="M118,324h8.75v.75H118Z"
                          transform="translate(-50.625 -303.75)"
                          fill="#28367a"
                          stroke="#28367a"
                          stroke-width="0.2"
                        />
                        <path
                          id="Tracé_2220"
                          data-name="Tracé 2220"
                          d="M275.75,189.5H275V188h-2.25v1.5H272v12.979l1.875,3.125,1.875-3.125V191.25h.75v4.625h.75V190.5h-1.5Zm-2.25-.75h.75v.75h-.75Zm1.5,1.5v1.25h-2.25v-1.25ZM272.75,202v-9.75H275V202Zm.287.75h1.675l-.838,1.4Z"
                          transform="translate(-195 -176.25)"
                          fill="#28367a"
                          stroke="#28367a"
                          stroke-width="0.2"
                        />
                        <path
                          id="Tracé_2221"
                          data-name="Tracé 2221"
                          d="M116.75,196H112v4.75h4.75Zm-.75,4h-3.25v-3.25H116Z"
                          transform="translate(-45 -183.75)"
                          fill="#28367a"
                          stroke="#28367a"
                          stroke-width="0.2"
                        />
                      </g>
                    </svg>
                  </span>
                </div>
              </div>
              <!-- <p class="stonk">
                <span>
                  4.6%
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="16"
                    height="16"
                  >
                    <path
                      d="M13 12V20H11V12H4L12 4L20 12H13Z"
                      fill="rgba(61,213,152,1)"
                    ></path>
                  </svg>
                </span>
                Plus que le mois dernier
              </p> -->
            </div>

            <div class="block">
              <div class="total">
                <div class="amount">
                  <h3>{{ $t("TOTAL") }} {{ $t("INVOICE") }}</h3>
                  <h1>{{ getstatistique.Total_factures }}</h1>
                </div>
                <div class="profil">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24.2"
                      height="28.2"
                      viewBox="0 0 24.2 32.2"
                    >
                      <g id="edition" transform="translate(-63.9 0.1)">
                        <path
                          id="Tracé_2209"
                          data-name="Tracé 2209"
                          d="M88,0H67.25V3.25H64V32H84.75V28.75H88ZM64.75,31.25V4H79V9h5V31.25Zm15-26.72,3.72,3.72H79.75ZM87.25,28h-2.5V8.47L79.53,3.25H68V.75H87.25Z"
                          fill="#28367a"
                          stroke="#28367a"
                          stroke-width="0.2"
                        />
                        <path
                          id="Tracé_2210"
                          data-name="Tracé 2210"
                          d="M118,116h10v.75H118Z"
                          transform="translate(-50.625 -108.75)"
                          fill="#28367a"
                          stroke="#28367a"
                          stroke-width="0.2"
                        />
                        <path
                          id="Tracé_2211"
                          data-name="Tracé 2211"
                          d="M118,148h8v.75h-8Z"
                          transform="translate(-50.625 -138.75)"
                          fill="#28367a"
                          stroke="#28367a"
                          stroke-width="0.2"
                        />
                        <path
                          id="Tracé_2212"
                          data-name="Tracé 2212"
                          d="M206,196h3.25v.75H206Z"
                          transform="translate(-133.125 -183.75)"
                          fill="#28367a"
                          stroke="#28367a"
                          stroke-width="0.2"
                        />
                        <path
                          id="Tracé_2213"
                          data-name="Tracé 2213"
                          d="M206,228h3.25v.75H206Z"
                          transform="translate(-133.125 -213.75)"
                          fill="#28367a"
                          stroke="#28367a"
                          stroke-width="0.2"
                        />
                        <path
                          id="Tracé_2214"
                          data-name="Tracé 2214"
                          d="M118,292h8.75v.75H118Z"
                          transform="translate(-50.625 -273.75)"
                          fill="#28367a"
                          stroke="#28367a"
                          stroke-width="0.2"
                        />
                        <path
                          id="Tracé_2215"
                          data-name="Tracé 2215"
                          d="M118,356h8.75v.75H118Z"
                          transform="translate(-50.625 -333.75)"
                          fill="#28367a"
                          stroke="#28367a"
                          stroke-width="0.2"
                        />
                        <path
                          id="Tracé_2216"
                          data-name="Tracé 2216"
                          d="M206,260h3.25v.75H206Z"
                          transform="translate(-133.125 -243.75)"
                          fill="#28367a"
                          stroke="#28367a"
                          stroke-width="0.2"
                        />
                        <path
                          id="Tracé_2217"
                          data-name="Tracé 2217"
                          d="M118,388h8.75v.75H118Z"
                          transform="translate(-50.625 -363.75)"
                          fill="#28367a"
                          stroke="#28367a"
                          stroke-width="0.2"
                        />
                        <path
                          id="Tracé_2218"
                          data-name="Tracé 2218"
                          d="M118,420h7v.75h-7Z"
                          transform="translate(-50.625 -393.75)"
                          fill="#28367a"
                          stroke="#28367a"
                          stroke-width="0.2"
                        />
                        <path
                          id="Tracé_2219"
                          data-name="Tracé 2219"
                          d="M118,324h8.75v.75H118Z"
                          transform="translate(-50.625 -303.75)"
                          fill="#28367a"
                          stroke="#28367a"
                          stroke-width="0.2"
                        />
                        <path
                          id="Tracé_2220"
                          data-name="Tracé 2220"
                          d="M275.75,189.5H275V188h-2.25v1.5H272v12.979l1.875,3.125,1.875-3.125V191.25h.75v4.625h.75V190.5h-1.5Zm-2.25-.75h.75v.75h-.75Zm1.5,1.5v1.25h-2.25v-1.25ZM272.75,202v-9.75H275V202Zm.287.75h1.675l-.838,1.4Z"
                          transform="translate(-195 -176.25)"
                          fill="#28367a"
                          stroke="#28367a"
                          stroke-width="0.2"
                        />
                        <path
                          id="Tracé_2221"
                          data-name="Tracé 2221"
                          d="M116.75,196H112v4.75h4.75Zm-.75,4h-3.25v-3.25H116Z"
                          transform="translate(-45 -183.75)"
                          fill="#28367a"
                          stroke="#28367a"
                          stroke-width="0.2"
                        />
                      </g>
                    </svg>
                  </span>
                </div>
              </div>
              <!-- <p class="bid">
                <span>
                  1.8%
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="16"
                    height="16"
                  >
                    <path
                      d="M13 12H20L12 20L4 12H11V4H13V12Z"
                      fill="rgba(228,38,27,1)"
                    ></path>
                  </svg>
                </span>
                Moins que le mois dernier
              </p> -->
            </div>

            <div class="block" v-if="!isSuperAdmin">
              <div class="total">
                <div class="amount">
                  <h3>{{ $t("CHIFFRE_AFFAIRE") }}</h3>
                  <h1 v-show="getstatistique.Chiffre_affaires">
                    {{ getstatistique.Chiffre_affaires | financial(true) }}
                  </h1>
                </div>
                <div class="profil">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="27"
                      height="27"
                      viewBox="0 0 27 27"
                    >
                      <path
                        id="Icon_material-euro-symbol"
                        data-name="Icon material-euro-symbol"
                        d="M22.5,27.75a9.72,9.72,0,0,1-8.64-5.25H22.5v-3H12.87a9.435,9.435,0,0,1,0-3H22.5v-3H13.86a9.731,9.731,0,0,1,14.985-2.9L31.5,7.95A13.48,13.48,0,0,0,9.78,13.5H4.5v3H9.09a12.545,12.545,0,0,0,0,3H4.5v3H9.78A13.48,13.48,0,0,0,31.5,28.05l-2.67-2.655A9.608,9.608,0,0,1,22.5,27.75Z"
                        transform="translate(-4.5 -4.5)"
                        fill="#28367a"
                      />
                    </svg>
                  </span>
                </div>
              </div>
              <!-- <p class="bid">
                <span>
                  1.8%
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="16"
                    height="16"
                  >
                    <path
                      d="M13 12H20L12 20L4 12H11V4H13V12Z"
                      fill="rgba(228,38,27,1)"
                    ></path>
                  </svg>
                </span>
                Moins que le mois dernier
              </p> -->
            </div>

            <div class="block" v-else>
              <div class="total">
                <div class="amount">
                  <h3>{{ $t("TOTAL") }} {{ $t("ENTREPRISES") }}</h3>
                  <h1>{{ getstatistique.Total_entreprises }}</h1>
                </div>
                <div class="profil">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25.865"
                      height="21.454"
                      viewBox="0 0 25.865 21.454"
                    >
                      <g
                        id="Icon_feather-users"
                        data-name="Icon feather-users"
                        transform="translate(0.75 0.769)"
                      >
                        <path
                          id="Tracé_2297"
                          data-name="Tracé 2297"
                          d="M1.5,29.145V26.93A4.43,4.43,0,0,1,5.93,22.5h8.86a4.43,4.43,0,0,1,4.43,4.43v2.215"
                          transform="translate(5.145 -9.21)"
                          fill="none"
                          stroke="#28367a"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1.5"
                        />
                        <path
                          id="Tracé_2298"
                          data-name="Tracé 2298"
                          d="M7.5,8.93A4.43,4.43,0,1,0,11.93,4.5,4.43,4.43,0,0,0,7.5,8.93Z"
                          transform="translate(3.575 -4.5)"
                          fill="none"
                          stroke="#28367a"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1.5"
                        />
                        <path
                          id="Tracé_2299"
                          data-name="Tracé 2299"
                          d="M30,29.2V26.981A4.43,4.43,0,0,1,33.323,22.7"
                          transform="translate(-30 -9.261)"
                          fill="none"
                          stroke="#28367a"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1.5"
                        />
                        <path
                          id="Tracé_2300"
                          data-name="Tracé 2300"
                          d="M27.331,4.7a4.43,4.43,0,0,0,0,8.583"
                          transform="translate(-19.579 -4.551)"
                          fill="none"
                          stroke="#28367a"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1.5"
                        />
                      </g>
                    </svg>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="block" style="padding: 24px 0">
            <div class="chart">
              <apexchart
                v-if="getstatistique"
                type="donut"
                height="100%"
                :options="pieOptions"
                :series="series"
              ></apexchart>
            </div>
          </div>
        </div>
      </article>
      <article>
        <div class="left">
          <div class="bottom">
            <div class="block">
              <apexchart
                type="line"
                ref="line"
                :options="lineOptions"
                :series="seriesLine"
              ></apexchart>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="block">
            <h2>{{ $t("CLIENT_NEW") }}</h2>
            <div
              class="clients"
              v-for="(client, i) in getstatistique.Latest_clients"
              :key="i"
            >
              <div class="client" v-if="isSuperAdmin">
                <div class="profil">
                  <span>{{ client.lib[0] }}</span>
                </div>
                <div class="desc">
                  <h5>{{ client.lib }}</h5>
                  <h6>{{ $t("ENTREPRISE") }} ID#{{ client.id }}</h6>
                </div>
              </div>
              <div class="client" v-else>
                <div class="profil">
                  <span>{{ client.nom[0] }}{{ client.prenom[0] }}</span>
                </div>
                <div class="desc">
                  <h5>{{ client.nom }} {{ client.prenom }}</h5>
                  <h6>{{ $t("CUSTOMER") }} ID#{{ client.id }}</h6>
                </div>
              </div>
              <div
                class="icons"
                @click="goToClient(client)"
                v-show="!isSuperAdmin"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21.101"
                  height="16.652"
                  viewBox="0 0 21.101 16.652"
                >
                  <g
                    id="Icon_feather-mail"
                    data-name="Icon feather-mail"
                    transform="translate(-1.607 -5)"
                  >
                    <path
                      id="Tracé_78083"
                      data-name="Tracé 78083"
                      d="M4.832,6H19.484a1.837,1.837,0,0,1,1.832,1.832V18.821a1.837,1.837,0,0,1-1.832,1.832H4.832A1.837,1.837,0,0,1,3,18.821V7.832A1.837,1.837,0,0,1,4.832,6Z"
                      fill="none"
                      stroke="#393939"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                    <path
                      id="Tracé_78084"
                      data-name="Tracé 78084"
                      d="M21.315,9l-9.158,6.41L3,9"
                      transform="translate(0 -1.168)"
                      fill="none"
                      stroke="#393939"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                  </g>
                </svg>
              </div>
            </div>
            <hr />
            <router-link to="/client" class="see-more">
              {{ $t("READ_MORE") }}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="32"
                height="32"
              >
                <path
                  d="M13.1714 12.0007L8.22168 7.05093L9.63589 5.63672L15.9999 12.0007L9.63589 18.3646L8.22168 16.9504L13.1714 12.0007Z"
                  fill="rgba(40,54,122,1)"
                ></path>
              </svg>
            </router-link>
          </div>
        </div>
      </article>

      <footer>
        <section class="banner-bottom">
          <h3>{{ $t("ACCESS_RAPIDE") }}</h3>
          <div class="groupe">
            <router-link to="/devis" class="fiche">
              <img src="@/assets/invoice.png" />
            </router-link>
            <div class="description">
              <h5>{{ $t("CREATE") }} {{ $t("ESTIMATE") }}</h5>
              <h6>{{ $t("CREATE") }} {{ $t("ESTIMATE") }}</h6>
            </div>
          </div>
          <div class="groupe">
            <router-link to="/facture" class="fiche">
              <img src="@/assets/invoice-1.png" />
            </router-link>
            <div class="description">
              <h5>{{ $t("CREATE") }} {{ $t("INVOICE") }}</h5>
              <h6>{{ $t("CREATE") }} {{ $t("INVOICE") }}</h6>
            </div>
          </div>
        </section>
        <div class="block-blue"></div>
        <hr />
        <p>{{ $t("COPYRIGHT") }}</p>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import formatMoney from "accounting-js/lib/formatMoney.js";

export default {
  data() {
    return {
      series: [],
      pieOptions: {
        title: {
          text: this.$t("PRESTATION"),
          align: "left",
          style: {
            fontSize: "16px",
            fontWeight: "bold",
            color: "#212559",
          },
        },
        colors: [],
        legend: {
          position: "left",
        },
        labels: [],
        dataLabels: {
          enabled: false,
        },
        responsive: [
          {
            breakpoint: 900,
            options: {
              chart: {
                width: "100%",
                height: "200px",
              },
            },
          },
        ],
      },
      lineOptions: {
        colors: ["#3DD598", "#FFBB00"],
        chart: {
          toolbar: {
            show: true,
            tools: {
              download: false,
            },
          },
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        title: {
          text: this.$t("INVOICE") + " & " + this.$t("AVOIR"),
          align: "left",
        },
        xaxis: {
          categories: [
            "Jan",
            "Fev",
            "Mar",
            "Avr",
            "Mai",
            "Jun",
            "Jul",
            "Aou",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
        yaxis: {
          title: {
            text: this.$t("NBRE_FACTURE_AVOIR"),
          },
        },
      },
      seriesLine: [
        {
          name: this.$t("INVOICE"),
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
        {
          name: this.$t("AVOIR"),
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
      ],
    };
  },

  computed: {
    ...mapGetters([
      "getstatistique",
      "getAllclients",
      "getTotalRowProduct",
      "getTotalRowforfait",
      "getTotalRowmainoeuvre",
      "getOnlineUser",
    ]),
    isSuperAdmin() {
      return this.getOnlineUser.role == "user.super-admin";
    },
  },

  methods: {
    ...mapActions([
      "statistique_entreprise",
      "all_clients",
      "all_avoirs",
      "factures",
      "all_products",
      "all_forfaits",
      "all_mainoeuvres",
    ]),

    goToClient(client) {
      if (client) this.$router.push("/client?id=" + client.id);
    },

    setupGraphe() {
      this.seriesLine[0].data = [];
      for (let i = 0; i < 12; i++) {
        let data = 0;
        for (const [key, value] of Object.entries(
          this.getstatistique.Factures_by_month
        )) {
          if (i + 1 == key.slice(-2)) {
            data += value;
          }
        }
        this.seriesLine[0].data.push(data);
      }

      this.seriesLine[1].data = [];
      for (let i = 0; i < 12; i++) {
        let data = 0;
        for (const [key, value] of Object.entries(
          this.getstatistique.Avoirs_by_month
        )) {
          if (i + 1 == key.slice(-2)) {
            data += value;
          }
        }
        this.seriesLine[1].data.push(data);
      }

      this.$refs.line.updateSeries(this.seriesLine);
    },
  },

  filters: {
    financial: function (value, noSymbol = false) {
      return formatMoney(value, {
        symbol: noSymbol ? "" : "€",
        thousand: " ",
        precision: 2,
        decimal: ",",
        format: "%v %s",
      });
    },
  },

  mounted() {
    this.statistique_entreprise().then(() => {
      console.log(this.getstatistique);
      if (this.isSuperAdmin) {
        this.series = [];
        this.series.push(this.getstatistique.Total_produits);
        this.pieOptions.colors.push("#28367A");
        this.pieOptions.labels.push(this.$t("TypePrestation.Fourniture"));

        this.series.push(this.getstatistique.Total_forfaits);
        this.pieOptions.colors.push("#21D59B");
        this.pieOptions.labels.push(this.$t("TypePrestation.Forfait"));

        this.series.push(this.getstatistique.Total_main_oeuvres);
        this.pieOptions.colors.push("#FFC700");
        this.pieOptions.labels.push(this.$t("TypePrestation.MainOeuvre"));
      }
      this.setupGraphe();
    });
    this.all_clients({
      page: 1,
      per_page: 5,
    });
    if (!this.isSuperAdmin) {
      this.series = [];
      this.all_products({
        page: 1,
        per_page: 1,
      }).then(() => {
        this.series.push(this.getTotalRowProduct);
        this.pieOptions.colors.push("#28367A");
        this.pieOptions.labels.push(this.$t("TypePrestation.Fourniture"));
        this.all_forfaits({
          page: 1,
          per_page: 1,
        }).then(() => {
          this.series.push(this.getTotalRowforfait);
          this.pieOptions.colors.push("#21D59B");
          this.pieOptions.labels.push(this.$t("TypePrestation.Forfait"));
          this.all_mainoeuvres({
            page: 1,
            per_page: 1,
          }).then(() => {
            this.series.push(this.getTotalRowmainoeuvre);
            this.pieOptions.colors.push("#FFC700");
            this.pieOptions.labels.push(this.$t("TypePrestation.MainOeuvre"));
          });
        });
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.containerPage {
  width: 95%;
  min-height: 100vh;
  padding: 72px 0 0;
  margin: auto;
}

article {
  @media only screen and (max-width: 900px) {
    display: block;
  }
  display: flex;
  justify-content: space-between;

  .left,
  .right {
    @media only screen and (max-width: 900px) {
      width: 100%;
    }
    .block,
    .blockdiv {
      @media only screen and (max-width: 900px) {
        padding: 16px 8px;
      }
      padding: 24px 16px;
      min-height: 150px;
      border-radius: 5px;
      background-color: #fff;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05);
      border-bottom: 1px solid #f2f2f2;
      margin-bottom: 12px;
    }
  }
}
.left {
  width: 74%;
  h1 {
    color: #393939;
    font-size: 18px;
    font-weight: 700;
  }
}

.top {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 12px;

  .block {
    @media only screen and (max-width: 900px) {
      width: 49%;
      min-width: auto;
    }
    @media only screen and (max-width: 450px) {
      width: 100%;
    }
    width: 24%;
  }
}

.right {
  width: 25%;
}

.block,
.blockdiv {
  h2 {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 12px;
  }

  .clients {
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .client {
      display: flex;
      align-items: center;
      gap: 8px;
      overflow: hidden;
      .profil {
        background-color: #f1f3ff;
        color: #28367a;
        font-size: 12px;
        min-width: 42px;
        min-height: 42px;
        border-radius: 50px;

        span {
          display: block;
          line-height: 42px;
          text-align: center;
        }
      }

      .desc {
        h5 {
          font-size: 12px;
          font-weight: 700;
          color: #393939;
          margin-bottom: 0;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        h6 {
          font-size: 12px;
          font-weight: 500;
          color: #515151;
          margin-bottom: 0;
        }
      }
    }

    .icons {
      @media only screen and (max-width: 450px) {
        display: none;
      }
      cursor: pointer;
    }
  }

  hr {
    @media only screen and (max-width: 900px) {
      margin: 0.5rem 0;
    }
    margin: 1rem 0;
  }
  .see-more {
    color: #28367a;
    display: block;
    padding: 0 8px;
    font-size: 14px;
    text-decoration: none;
    width: fit-content;
  }

  .total {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .amount {
      h3 {
        font-size: 16px;
        font-weight: 600;
        color: #393939;
      }

      h1 {
        color: #0f0f0f;
        font-size: 30px;
        font-weight: 700;
      }
    }
    .profil {
      background-color: #f1f3ff;
      min-width: 62px;
      min-height: 62px;
      border-radius: 50px;

      span {
        display: block;
        line-height: 62px;
        text-align: center;
      }
    }
  }

  .stonk {
    font-size: 12px;
    color: #7e84a3;
    span {
      font-size: 18px;
      color: #3dd598;
    }
  }
  .bid {
    font-size: 12px;
    color: #7e84a3;
    span {
      font-size: 18px;
      color: #e4261b;
    }
  }
}

.blockdiv {
  display: flex;
  h2 {
    font-size: 12px;
    white-space: nowrap;
  }
  .first {
    width: 40%;
  }
  .second {
    width: 60%;
  }
}

// header {
//   @media only screen and (max-width: 900px) {
//     display: block;
//   }
//   background-color: #f5f6f8;
//   padding: 72px 24px 14px;
//   box-shadow: 2px 0 2px rgba($color: #000, $alpha: 0.05);
//   display: flex;
//   align-items: center;
//   justify-content: space-between;

//   h1 {
//     font-size: 16px;
//     color: #242424;
//     font-weight: bold;
//     margin-bottom: 0;
//     line-height: 1;
//   }
// }

footer {
  .block-blue {
    width: 100%;
    height: 28px;
    background-color: #28367a;
    border-radius: 6px;
    margin-bottom: 12px;
  }

  hr {
    margin-bottom: 0;
  }

  p {
    padding: 10px 15px;
    font-size: 12px;
    color: #515151;
    text-align: right;
  }
}

.banner-bottom {
  @media only screen and (max-width: 450px) {
    flex-direction: column;
    gap: 8px;
  }
  position: relative;
  background-color: #fff;
  display: flex !important;
  justify-content: space-between;
  padding: 24px;
  border-bottom: 1px solid #f2f2f2;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05);
  margin-bottom: 12px;

  h3 {
    @media only screen and (max-width: 900px) {
      display: none;
    }
    font-size: 16px;
    font-weight: 700;
    width: 15%;
    white-space: nowrap;
  }

  .groupe {
    @media only screen and (max-width: 900px) {
      gap: 4px;
    }
    flex-grow: 1;
    display: flex;
    gap: 12px;
    align-items: center;
    color: #fff;
    text-align: center;

    .fiche {
      @media only screen and (max-width: 900px) {
        height: 52px;
        width: 52px;
      }
      padding: 8px;
      background-color: #24316d;
      border-radius: 50%;
      height: 72px;
      width: 72px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        @media only screen and (max-width: 900px) {
          width: 24px;
        }
        width: 32px;
      }

      &:hover {
        background-color: transparent;
        transform: scale(1.2);
      }
    }

    & h5 {
      @media only screen and (max-width: 900px) {
        font-size: 16px;
        margin-bottom: 4px;
      }
      color: #393939;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 4px;
    }

    & h6 {
      @media only screen and (max-width: 900px) {
        font-size: 12px;
      }
      text-align: left;
      color: #989898;
      margin: 0;
      font-size: 14px;
      font-weight: 400;
      max-width: 260px;
    }
  }
}
</style>
